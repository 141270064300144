import React, { useCallback, useEffect, useState } from "react";
import { GrFormClose } from "react-icons/gr";
import { AddButton, ImageInput, NumberInput, SelectInput, ShowImage, TextAreaInput, TextInput } from "../Common/FormComponents";
import { toast } from "react-toastify";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { IoSearchOutline } from "react-icons/io5";
import { addMedicine, getMedicines } from "../../utils/medicinesUtils/medicinesUtils";
import { useForm } from "../../hooks";
import { getCategories } from "../../utils/homeUtils/CategoriesUtils";
import { getBrands } from "../../utils/homeUtils/brandsUtils";

const MedicineModal = ({ setOpen, setDatasFn, editData = null, setEditData }) => {

  const medicineInitialState = {
    medicine_name: editData?.medicine_name || "",
    actual_price: editData?.actual_price || 0,
    discount: editData?.discount || 0,
    packSize: editData?.packSize || 0,
    price_per_pack: editData?.price_per_pack || 0,
    availability: editData?.availability || "",
    manufacturer: editData?.manufacturer || "",
    consume_types: editData?.consume_types || "",
    expiry_dates: editData?.expiry_dates || "",
    category: editData?.category?.category_name || "Select Category",
    brand: editData?.brand?.brand_name || "Select Brand",
    about: editData?.about || "",
    uses: editData?.uses || "",
    medical_benefits: editData?.medical_benefits || "",
    direction_of_use: editData?.direction_of_use || "",
    storage: editData?.storage || "",
    side_effects: editData?.side_effects || "",
    drug_warning: editData?.drug_warning || "",
    drug_interactions: editData?.drug_interactions || "",
    drug_interactions_list: editData?.drug_interactions_list || "",
    special_advice: editData?.special_advice || "",
    disease_condition: editData?.disease_condition || "",
    country_of_orgin: editData?.country_of_orgin || "",
    manufacturer_address: editData?.manufacturer_address || "",
    slug: editData?.slug || "",
    total_price: editData?.total_price || 0,
  };

  const [editFaqIndex, setEditFaqIndex] = useState(null);

  const [medFormData, handleMedData, clearForm] = useForm(medicineInitialState);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [cats, setCats] = useState([]);
  const [brandOptions, setBrandOptions] = useState([]);
  const [catId, setCatId] = useState(0);
  const [brandId, setBrandId] = useState(0);

  const faqInitialState = { faq_question: "", faq_answer: "" };
  const [faqFormData, handleFaqFromData, clearFaqForm] = useForm(faqInitialState);
  const [faq, setFaqs] = useState(editData?.faq || []);

  const sizeInitialState = { size: "" };
  const [sizeFormData, handleSizeFromData, clearSizeForm] = useForm(sizeInitialState);
  const [available_size, setSizes] = useState(editData?.available_size || []);

  useEffect(() => {
    getCategories()
      .then((res) => {
        setCategories([
          { category_name: "Select Category", _id: 0 },
          ...res.data.data,
        ]);
        setCats([
          "Select Category",
          ...res.data.data.map((item) => item.category_name),
        ]);
      })
      .catch(() => {
        setCategories([{ category_name: "Select Category", _id: 0 }]);
      });

    getBrands()
      .then((res) => {
        setBrands([
          { brand_name: "Select Brand", _id: 0 },
          ...res.data.data,
        ]);
        setBrandOptions([
          "Select Brand",
          ...res.data.data.map((item) => item.brand_name),
        ]);
      })
      .catch(() => {
        setBrands([{ brand_name: "Select Brand", _id: 0 }]);
      });
  }, []);

  useEffect(() => {
    const foundCategory = categories.find(
      (cat) => cat.category_name === medFormData.category
    );
    if (foundCategory) {
      setCatId(foundCategory._id);
    }

    const foundBrand = brands.find(
      (brand) => brand.brand_name === medFormData.brand
    );
    if (foundBrand) {
      setBrandId(foundBrand._id);
    }
  }, [categories, brands, medFormData.category, medFormData.brand]);

  const [medicines, setMedicines] = useState([]);
  const [selected, setSelected] = useState(null);
  const setMedicinesFn = useCallback(() => {
    getMedicines()
      .then((res) => setMedicines(res.data.data))
      .catch((error) => console.error(error));
  }, []);
  useEffect(() => {
    setMedicinesFn();
  }, [setMedicinesFn]);
  const [searchQuery, setSearchQuery] = useState("");
  const [related_products, setRelatedProducts] = useState(editData?.related_products || []);
  const handleProductsData = (action, idx = 0, item = null) => {
    if (action === "add") {
      if (!item) return;
      const exist = related_products.find(data => data?._id === item?._id);
      if (exist) return;
      setSelected(item);
      setRelatedProducts([
        ...related_products,
        item,
      ]);
    } else if (action === "remove") {
      setRelatedProducts(related_products.filter((_, index) => index !== idx));
    }
  };
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    const filteredData = medicines?.filter((medicine) => {
      const nameMatch = medicine?.medicine_name
        ?.toLowerCase()
        ?.includes(searchQuery.toLowerCase());
      return nameMatch;
    });
    setFilteredData(filteredData);
  }, [medicines, searchQuery]);

  const [images, setImages] = useState(editData?.med_images || []);
  const [adImages, setAdImages] = useState(editData?.adV_banner || []);
  const [image, setImage] = useState(null);
  const [adImage, setAdImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState({ global: "" });

  // Image handling
  const handleImages = (action, idx = 0, type = "med") => {
    setErr({ global: "" });
    const imgState = type === "med" ? images : adImages;
    const setImgState = type === "med" ? setImages : setAdImages;

    if (action === "add") {
      if ((type === "med" && !image) || (type === "ad" && !adImage)) {
        return setErr({ global: `Please select ${type === "med" ? "an image" : "an ad image"}` });
      }
      setImgState([...imgState, type === "med" ? image : adImage]);
      type === "med" ? setImage(null) : setAdImage(null);
    } else if (action === "remove") {
      setImgState(imgState.filter((_, index) => index !== idx));
    }
  };

  const handleFaqs = (action, idx = 0) => {
    if (action === "add") {
      if (!faqFormData.faq_question.trim().length || !faqFormData.faq_answer.trim().length) {
        return setErr({ faq: "Please Enter Question & Answer" });
      }
      if (editFaqIndex !== null) {
        const updatedFaqs = faq.map((item, index) =>
          index === editFaqIndex ? faqFormData : item
        );
        setFaqs(updatedFaqs);
        setEditFaqIndex(null);
      } else {
        setFaqs([...faq, faqFormData]);
      }
      clearFaqForm();
    } else if (action === "remove") {
      setFaqs(faq.filter((_, index) => index !== idx));
    }
  };

  const handleSizez = (action, idx = 0) => {
    if (action === "add") {
      if (!sizeFormData.size.trim().length) {
        return setErr({ faq: "Please Enter Size" });
      }
      setSizes([...available_size, sizeFormData.size]);
      clearSizeForm();
    } else if (action === "remove") {
      setSizes(available_size.filter((_, index) => index !== idx));
    }
  };

  const handleFaqClick = (index) => {
    const selectedFaq = faq[index];
    handleFaqFromData({ target: { name: "faq_question", value: selectedFaq.faq_question } });
    handleFaqFromData({ target: { name: "faq_answer", value: selectedFaq.faq_answer } });
    setEditFaqIndex(index);
  };
  // Submit handler
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!medFormData?.medicine_name.trim().length) {
      setErr({ global: "Invlid Name" });
      return;
    }
    if (catId === 0) {
      setErr({ global: "Please Select a Category" });
      return;
    }
    if (brandId === 0) {
      setErr({ global: "Please Select a Brand" });
      return;
    }
    if (isNaN(medFormData?.actual_price) || medFormData?.actual_price < 0) {
      setErr({ global: "Invlid Actual Price" });
      return;
    }
    if (isNaN(medFormData?.discount) || medFormData?.discount < 0) {
      setErr({ global: "Invlid Discount" });
      return;
    }
    if (!medFormData?.expiry_dates.trim().length) {
      setErr({ global: "Invlid Expiry Date" });
      return;
    }
    if (!images.length) return setErr({ global: "Please add at least one medicine image" });
    // if (!adImages.length) return setErr({ global: "Please add at least one advertisment image" });
    setLoading(true);
    addMedicine(medFormData, catId, brandId, available_size, images, adImages, related_products, faq, editData?._id)
      .then((res) => {
        setDatasFn();
        setOpen(false);
        clearForm();
        setAdImages([]);
        setSizes([]);
        setImages([]);
        setErr({ global: "", faq: "" });
        setEditData(null);
        toast(`Medicine ${editData ? "Edited" : "Added"} successfully`, { type: "success", autoClose: 3000 });
      })
      .catch((err) => {
        console.error(err);
        toast(`Medicine Failed to ${editData ? "edit" : "add"}`, { type: "error", autoClose: 3000 });
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="absolute flex w-full h-fit top-0 left-0 bottom-0 justify-center items-center p-4">
      <div
        className="flex  w-full max-w-screen-sm bg-white overflow-y-auto max-h-screen  border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col"
      >
        <div className="flex w-full h-fit items-center justify-between font-semibold ">
          <span>{editData ? "Edit Medicine" : "Add Medicine"}</span>
          <span className="cursor-pointer" onClick={() => {setOpen(false);setEditData(null);}}>
            <GrFormClose fontSize={16} />
          </span>
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <TextInput name='Medicine Name' formName={"medicine_name"} data={medFormData} handleInput={handleMedData} setError={setErr} />
        </div>
        <div className="flex w-full h-fit justify-between gap-5">
          <SelectInput items={brandOptions} name='Brand' formName={"brand"} data={medFormData} handleInput={handleMedData} setError={setErr} />
          <SelectInput items={cats} name='Category' formName={"category"} data={medFormData} handleInput={handleMedData} setError={setErr} />
        </div>
        <div className="flex w-full h-fit gap-5">
          <NumberInput name={"Actual Price"} min={0}  formName={'actual_price'} data={medFormData} handleInput={handleMedData} setError={setErr} />
          <NumberInput name={"Discount"} min={0} max={100} formName={'discount'} data={medFormData} handleInput={handleMedData} setError={setErr} />
          <NumberInput name="Total Price (auto-calc)" hide={true} disabled={true} formName={"total_price"} data={medFormData} handleInput={handleMedData} setError={setErr} />
        </div>

        <div className="flex w-full h-fit gap-5">
          <NumberInput hide={true} name={"Pack Size"} formName={'packSize'} data={medFormData} handleInput={handleMedData} setError={setErr} />
          <NumberInput hide={true} name={"Price Per Pack"} formName={'price_per_pack'} data={medFormData} handleInput={handleMedData} setError={setErr} />
        </div>

        {available_size?.length > 0 && (
          <div className="flex w-full gap-1 flex-wrap">
            {available_size?.map((item, idx) => (
              <div
                className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs"
                key={idx}
              >
                <span>{item}</span>
                <button
                  onClick={() => {
                    handleSizez("remove", idx);
                  }}
                >
                  <AiOutlineCloseCircle fontSize={14} color="red" />
                </button>
              </div>
            ))}
          </div>
        )}

        <div className="feild-conatainer flex-col">
          <TextInput
            hide={true}
            data={sizeFormData}
            handleInput={handleSizeFromData}
            name={"Available Sizes"}
            formName={"size"}
            setError={setErr}
          />
          <AddButton handleAdd={handleSizez} />
        </div>

        <div className="flex w-full h-fit justify-between gap-5">
          <TextAreaInput hide={true} name={"Uses"} formName={'uses'} data={medFormData} handleInput={handleMedData} setError={setErr} />
          <TextAreaInput hide={true} name={"Medical Benefits"} formName={'medical_benefits'} data={medFormData} handleInput={handleMedData} setError={setErr} />
        </div>

        <div className="flex w-full h-fit gap-5">
          <TextInput hide={true} name="Availability" formName={"availability"} data={medFormData} handleInput={handleMedData} setError={setErr} />
          <TextInput hide={true} name="Manufacturer" formName={"manufacturer"} data={medFormData} handleInput={handleMedData} setError={setErr} />
        </div>

        <div className="flex w-full h-fit gap-5">
          <TextInput hide={true} name="Consume Types" formName={"consume_types"} data={medFormData} handleInput={handleMedData} setError={setErr} />
          <TextInput name="Expiry Dates" formName={"expiry_dates"} data={medFormData} handleInput={handleMedData} setError={setErr} />
        </div>

        <div className="flex w-full h-fit gap-5">
          <TextAreaInput hide={true} name="About" formName={"about"} data={medFormData} handleInput={handleMedData} setError={setErr} />
          <TextAreaInput hide={true} name="Direction of Use" formName={"direction_of_use"} data={medFormData} handleInput={handleMedData} setError={setErr} />
        </div>

        <div className="flex w-full h-fit gap-5">
          <TextAreaInput hide={true} name="Storage" formName={"storage"} data={medFormData} handleInput={handleMedData} setError={setErr} />
          <TextAreaInput hide={true} name="Side Effects" formName={"side_effects"} data={medFormData} handleInput={handleMedData} setError={setErr} />
        </div>

        <div className="flex w-full h-fit gap-5">
          <TextAreaInput hide={true} name="Drug Warning" formName={"drug_warning"} data={medFormData} handleInput={handleMedData} setError={setErr} />
          <TextAreaInput hide={true} name="Drug Interactions" formName={"drug_interactions"} data={medFormData} handleInput={handleMedData} setError={setErr} />
        </div>

        <div className="flex w-full h-fit gap-5">
          <TextAreaInput hide={true} name="Drug Interactions List" formName={"drug_interactions_list"} data={medFormData} handleInput={handleMedData} setError={setErr} />
          <TextAreaInput hide={true} name="Special Advice" formName={"special_advice"} data={medFormData} handleInput={handleMedData} setError={setErr} />
        </div>

        <div className="flex w-full h-fit gap-5">
          <TextInput hide={true} name="Disease Condition" formName={"disease_condition"} data={medFormData} handleInput={handleMedData} setError={setErr} />
          <TextInput hide={true} name="Country of Origin" formName={"country_of_orgin"} data={medFormData} handleInput={handleMedData} setError={setErr} />
        </div>

        <div className="flex w-full h-fit gap-5">
          <TextInput hide={true} name="Manufacturer Address" formName={"manufacturer_address"} data={medFormData} handleInput={handleMedData} setError={setErr} />
        </div>

        {related_products?.length > 0 && (
          <div className="flex w-full gap-1 flex-wrap">
            {related_products?.map((item, idx) => (
              <div
                className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs"
                key={idx}
              >
                <div className="flex items-center gap-1 ">
                  <span>{item?.medicine_name}</span>
                </div>
                <button
                  onClick={() => {
                    handleProductsData("remove", idx);
                  }}
                >
                  <AiOutlineCloseCircle fontSize={14} color="red" />
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="relative flex flex-col gap-0.5">
          <span className="absolute inset-y-0 left-0 top-5 flex items-center pl-2">
            <IoSearchOutline fontSize={23} />
          </span>
          <label
            htmlFor={"List Of Medicines"}
            className="text-[14px] text-shadow-black "
          >
            {"List Of Medicines"}
          </label>
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}
            className="appearance-none rounded-md w-full bg-white border border-gray-300 py-2 pl-10 pr-4 text-gray-700 leading-tight focus:outline-none focus:border-primary"
            placeholder="Search By Name"
          />
        </div>
        <div className="flex gap-5 flex-wrap min-h-[100px] max-h-[100px] overflow-y-scroll px-5 m-3 ">
          {filteredData.length > 0 ? (
            filteredData.map((item) => (
              <div
                key={item._id}
                onClick={() => {
                  handleProductsData("add", null, item);
                }}
                className={`flex px-2 py-1 cursor-pointer w-fit h-fit  border rounded-md ${selected?._id === item?._id
                    ? `text-white bg-primary`
                    : `text-black`
                  }`}
              >
                {`${item?.medicine_name}`}
              </div>
            ))
          ) : (
            <div className="flex h-full w-full justify-center items-center text-white">
              No Doctors Found!
            </div>
          )}
        </div>

        {images?.length > 0 && (
          <div className="flex w-full gap-1 flex-wrap">
            {images.map((item, idx) => (
              <div className="flex gap-2 items-center border rounded-md p-1 shadow-md" key={idx}>
                <img src={item instanceof File ? URL.createObjectURL(item) : item} alt="med_image" className="w-10 h-10 object-cover rounded" />
                <button onClick={() => handleImages("remove", idx, "med")}>
                  <AiOutlineCloseCircle fontSize={14} color="red" />
                </button>
              </div>
            ))}
          </div>
        )}
        <ImageInput imageName="Image" setImage={setImage} />
        <ShowImage image={image} setImage={setImage} />
        <AddButton handleAdd={() => handleImages("add", 0, "med")} />

        {adImages?.length > 0 && (
          <div className="flex w-full gap-1 flex-wrap mt-4">
            {adImages.map((item, idx) => (
              <div className="flex gap-2 items-center border rounded-md p-1 shadow-md" key={idx}>
                <img src={item instanceof File ? URL.createObjectURL(item) : item} alt="ad_image" className="w-10 h-10 object-cover rounded" />
                <button onClick={() => handleImages("remove", idx, "ad")}>
                  <AiOutlineCloseCircle fontSize={14} color="red" />
                </button>
              </div>
            ))}
          </div>
        )}
        <ImageInput imageName="Advertisment Image" setImage={setAdImage} />
        <ShowImage image={adImage} setImage={setAdImage} />
        <AddButton handleAdd={() => handleImages("add", 0, "ad")} />

        {faq?.length > 0 && (
          <div className="flex w-full gap-1 flex-wrap">
            {faq?.map((item, idx) => (
              <div
                className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs"
                key={idx}
              >
                <span className='cursor-pointer' onClick={() => handleFaqClick(idx)}>{item?.faq_question}</span>
                <button
                  onClick={() => {
                    handleFaqs("remove", idx);
                  }}
                >
                  <AiOutlineCloseCircle fontSize={14} color="red" />
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="feild-conatainer flex-col">
          <TextInput
            data={faqFormData}
            handleInput={handleFaqFromData}
            name={"Question"}
            formName={"faq_question"}
            setError={setErr}
          />
          <div className="flex">
            <TextAreaInput
              data={faqFormData}
              handleInput={handleFaqFromData}
              name={"Answer"}
              formName={"faq_answer"}
              setError={setErr}
            />
            <AddButton handleAdd={handleFaqs} />
          </div>
        </div>
        {err.faq && <div className="error-message">{err.faq}</div>}
        {editData && 
          <TextInput name="Slug" formName={"slug"} data={medFormData} handleInput={handleMedData} setError={setErr} />
        }
        {err.global && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.global}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
              onClick={() => {
                setOpen(false);
                setEditData(null);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
              disabled={loading}
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MedicineModal;
