import { axiosAuthorized } from "../../api/config"

export const getBanner = async () => {
    try {
        const response = await axiosAuthorized.get(`/getmainadd`);
        return response;
    } catch (error) {
        throw error;
    }
}


export const addBanner = async (main_ad, id = null) => {
    try {
        const requestData = {
            main_ad
        };

        const requestConfig = {
            headers: { "Content-Type": "multipart/form-data" },
        };

        const url = `/mainadd/add_mainadd`;

        const response = await axiosAuthorized['post'](url, requestData, requestConfig);
        return response;
    } catch (error) {
        throw error;
    }
}

export const deleteBanner = async (id) => {
    try {
        const response = await axiosAuthorized.delete(`/mainadd/delete_mainadd/${id}`);
        return response;
    } catch (error) {
        throw error;
    }
}