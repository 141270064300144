import { axiosAuthorized } from "../../api/config"

export const getCategories = async () => {
    try {
        const response = await axiosAuthorized.get(`/getcategory`);
        return response;
    } catch (error) {
        throw error;
    }
}


export const addCategory = async (initialState, id = null) => {
    try {
        const requestData = {
            ...initialState,
        };

        const requestConfig = {
            headers: { "Content-Type": "application/json" },
        };

        const url = id ? `/category/update_category/${id}` : `/category/add_category`;

        const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
        return response;
    } catch (error) {
        throw error;
    }
}

export const deleteCategory= async (id) => {
    try {
        const response = await axiosAuthorized.delete(`/category/delete_category/${id}`);
        return response;
    } catch (error) {
        throw error;
    }
}