import { Layout } from './layout';
import { Route, Routes } from 'react-router-dom';
import { AuthProtected, UserProtected } from './components/ProtectedRoutes/UserProtected';
import { Categories, Coupons, Home, Login, Medicines, Orders } from './pages';

function App() {
  return (
    <Routes>
      <Route path="/" element={<UserProtected element={<Layout />} />}>
        <Route index={true} element={<Medicines />} />
        <Route path='/orders' element={<Orders />} />
        <Route path="/categories" element={<Categories />} />
        <Route path="/home" element={<Home />} />
        <Route path="/coupons" element={<Coupons />} />
      </Route>
      <Route path="/login" element={<AuthProtected element={<Login />} />} />
    </Routes>
  );
}

export default App;
