import { axiosAuthorized } from "../../api/config"

export const getImages = async () => {
    try {
        const response = await axiosAuthorized.get(`/getsecondaryimage`);
        return response;
    } catch (error) {
        throw error;
    }
}


export const addImages = async (icons, id = null) => {
    try {
        const requestData = new FormData();
        icons.forEach((image) => {
            requestData.append("secondary_image", image);
        });
        const requestConfig = {
            headers: { "Content-Type": "multipart/form-data" },
        };

        const url = id
            ? `/secondaryimage/update_secondaryimage/${id}`
            : `/secondaryimage/add_secondaryimage`;

        const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
        return response;
    } catch (error) {
        throw error;
    }
}

export const deleteImages = async (id) => {
    try {
        const response = await axiosAuthorized.delete(`/secondaryimage/delete_secondaryimage/${id}`);
        return response;
    } catch (error) {
        throw error;
    }
}