import React from "react";
import CouponsMainSection from "../../components/CouponsComponents/CouponsMainSection";
const Coupons = () => {
    return (
        <div className=" w-full h-full px-2 py-10 sm:p-10 flex flex-col gap-4 relative">
            <CouponsMainSection/>
        </div>
    );
};

export default Coupons;
