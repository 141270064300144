import { axiosAuthorized } from "../../api/config"

export const getCoupons = async () => {
  try {
    const response = await axiosAuthorized.get(`/coupon/get_coupon`);
    return response;
  } catch (error) {
    throw error;
  }
}


export const addCoupon = async (initialState, id = null) => {
  try {
    const requestData = {
      ...initialState,
    };

    const requestConfig = {
      headers: { "Content-Type": "application/json" },
    };

    const url = id
      ? `/coupon/update_coupon/${id}`
      : `/coupon/add_coupon`;

    const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteCoupon = async (id) => {
  try {
    const response = await axiosAuthorized.delete(`/coupon/delete_coupon/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}