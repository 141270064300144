import { axiosAuthorized } from "../../api/config"

export const getBrands = async () => {
    try {
        const response = await axiosAuthorized.get(`/getbrand`);
        return response;
    } catch (error) {
        throw error;
    }
}


export const addBrand = async (initialState, id = null) => {
    try {
        const requestData = {
            ...initialState,
        };

        const requestConfig = {
            headers: { "Content-Type": "application/json" },
        };

        const url = id ? `/brand/update_brand/${id}` : `/brand/add_brand`;

        const response = await axiosAuthorized[id ? 'put' : 'post'](url, requestData, requestConfig);
        return response;
    } catch (error) {
        throw error;
    }
}

export const deleteBrand = async (id) => {
    try {
        const response = await axiosAuthorized.delete(`/brand/delete_brand/${id}`);
        return response;
    } catch (error) {
        throw error;
    }
}