import React, { useMemo } from 'react'
import Table from '../../Common/Table/Table';

const OnlineAppointmentsTable = ({ datas, setOpenTracker, setEditData }) => {
      const headings = useMemo(
        () => ["Name","Phone","Email","Shipping Address","Pincode", "Medicines","Price", "Order-Placed-Date", "Order-Placed-Time", "Expected-Date", "Expected-Time","Tracker"],
        []
      );
  return (
    <>
      <Table headings={headings}>
        {datas?.map((data, index) => (
          <tr key={index}>
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="text-sm text-gray-900">{data?.patient_name}</div>
            </td>

            <td className="px-6 py-4 whitespace-nowrap">
              <div className="text-sm text-gray-900 capitalize">
                {data?.patient_phonenumber}
              </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="text-sm text-gray-900 capitalize">
                {data?.patient_email}
              </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="text-sm text-gray-900 capitalize">
                {data?.patient_address}
              </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="text-sm text-gray-900 capitalize">
                {data?.patient_pincode}
              </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="text-sm text-gray-900 capitalize flex flex-col max-h-[100px] overflow-y-auto">
                {data?.medicine?.length > 0 ? (
                  data?.medicine?.map((item, idx) => (
                    <span key={idx}>
                      {" "}
                      {idx + 1} {". "} {item?.medicine_name}
                    </span>
                  ))
                ) : (
                  <span>No Medicines !</span>
                )}
                </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="text-sm text-gray-900 capitalize">
                {data?.total_amount}
              </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="text-sm text-gray-900">
                {data?.createdAt
                  ? new Date(data?.createdAt).toLocaleDateString()
                  : "N/A"}
              </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="text-sm text-gray-900">
                {data?.createdAt
                  ? new Date(data?.createdAt).toLocaleTimeString(
                    "en-US",
                    {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: true,
                      timeZone: "UTC",
                    }
                  )
                  : "N/A"}
              </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="text-sm text-gray-900 capitalize">
                {data?.expected_date || "-"}
              </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="text-sm text-gray-900 capitalize">
                {data?.expected_time || "-"}
              </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="text-sm text-gray-900 capitalize cursor-pointer">
                <button
                  className="text-white text-sm bg-primary rounded-md py-1 px-2"
                  onClick={() => {
                    setEditData(data);
                    setOpenTracker(true);
                  }}
                >
                  Update
                </button>
              </div>
            </td>
          </tr>
        ))}
      </Table>
    </>
  );
}

export default OnlineAppointmentsTable