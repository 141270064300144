import React, { useMemo } from "react";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { TbUserEdit } from "react-icons/tb";
import { deleteCategory } from "../../utils/homeUtils/CategoriesUtils";
import Table from "../Common/Table/Table";

const CategoriesTable = ({ datas, setOpen, setEditData, setCategoriesFn, setCategory }) => {
  const headings = useMemo(
    () => [
        "Category",
        "Medicines",
        "Edit",
        "Delete"
    ],
    []
  );
  const delCategory = (categoryId) => {
    const confirmed = window.confirm("Are you sure you want to delete ?");
    if (!confirmed) return;
    deleteCategory(categoryId)
      .then((res) => setCategoriesFn())
        .catch((err) => console.log(err))
  };
  return (
    <Table headings={headings}>
      {datas?.map((data, index) => (
        <tr key={index}>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900">{data?.category_name}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <button
              className="flex justify-center items-center gap-2 text-xs border rounded-md bg-blue-600 text-white p-2"
              onClick={() => {
                setCategory(data);
              }}
            >
              Medicines
            </button>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div
              className="text-sm text-gray-900 capitalize cursor-pointer"
              onClick={() => {
                setEditData(data);
                setOpen(true);
              }}
            >
              <TbUserEdit />
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div
              className="text-sm text-gray-900 capitalize cursor-pointer"
              onClick={() => {
                delCategory(data?._id);
              }}
            >
              <MdOutlineDeleteOutline color="red" />
            </div>
          </td>
        </tr>
      ))}
    </Table>
  );
};

export default CategoriesTable;
