import React from 'react'
import MainBanner from './MainBanner';
import Brands from './Brands';
import BannerImages from './BannerImages';

const HomeMainSection = () => {
  return (
    <>
      <div className=" flex w-full items-center gap-5 md:gap-0 flex-col">
        <div className="flex flex-col w-full gap-5">
          <Brands />
          <MainBanner />
          <BannerImages/>
        </div>
      </div>
    </>
  );
}

export default HomeMainSection