export const validateLoginForm = (initialState, setErr) => {
    const idPattern = /^.+$/;
    const passwordPattern = /^.{8,}$/

    if (!initialState.phram_member_id.trim() || !idPattern.test(initialState.phram_member_id.trim())) {
        setErr({ global: "Invalid Id" });
        return;
    }

    if (!passwordPattern.test(initialState.password)) {
        setErr({ global: "Invalid Password" });
        return;
    }
    return true;
};