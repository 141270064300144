import React from 'react'
import MedicinesMainSection from '../../components/MedicinesComponents/MedicinesMainSection'

const Tests = () => {
  return (
    <div className=" w-full h-full px-2 py-10 sm:p-10 flex flex-col gap-4 relative">
      <MedicinesMainSection />
    </div>
  );
}

export default Tests