import React, { useCallback, useEffect, useState } from "react";
import { NoDataFound } from "../Common";
import Table from "../Common/Table/Table";
import BannerImagesModal from "./BannerImagesModal";
import { getImages } from "../../utils/homeUtils/secondaryImagesUtils";

const BannerImages = () => {
  const [open, setOpen] = useState(false);
  const [datas, setDatas] = useState({});
  const setDatasFn = useCallback(() => {
    getImages()
      .then((res) => setDatas(res?.data?.data[0]))
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    setDatasFn();
  }, [setDatasFn]);
  const [editData, setEditData] = useState(null);
  return (
    <div className="w-full h-fit flex flex-col gap-5 relative">
      {open && (
        <BannerImagesModal
          setOpen={setOpen}
          setDatasFn={setDatasFn}
          editData={editData}
          setEditData={setEditData}
        />
      )}
      <div className="flex w-full h-fit text-base font-semibold gap-5 items-center">
        <span>Secondary Images</span>
        <button
          style={{
            background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
          }}
          onClick={() => { setOpen(true); setEditData(datas); }}
          className="flex items-center gap-2 px-2 py-1 text-white text-sm font-normal rounded-md"
        >
          <span>Update</span>
        </button>
      </div>
      {datas?.secondary_image?.length > 0 ? (
        <Table headings={["Image"]}>
          {datas?.secondary_image?.map((data, index) => (
            <tr key={index}>
              <td className="px-6 py-4 whitespace-nowrap">
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">
                    <div className="flex-shrink-0  h-16 w-16  justify-center items-center  rounded-md flex">
                      <img
                        className="rounded-md h-16 w-16 object-cover"
                        src={data}
                        alt={`secondary_image-${index}`}
                      />
                    </div>
                  </div>
                </td>
              </td>
            </tr>
          ))}
        </Table>
      ) : (
        <NoDataFound data={"Secondary Images"} />
      )}
    </div>
  );
};

export default BannerImages;
