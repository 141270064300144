import { axiosAuthorized } from "../../api/config"

export const getMedicines = async () => {
  try {
    const response = await axiosAuthorized.get(`/medPhram/get_medPhram`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const getMedicinesByCategory = async (id) => {
  try {
    const response = await axiosAuthorized.get(`category_wise_medicine/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}

export const addMedicine = async (
  initialState,
  categoryId,
  brandId,
  available_size,
  med_images = [],
  adV_banner = [],
  related_products = [],
  faq = [],
  id = null
) => {
  try {
    const { category, brand, ...rest } = initialState;
    related_products = related_products?.map(item => item?._id);

    const discountDecimal = Number(rest.discount) / 100;
    const discountAmount = Number(rest.actual_price) * discountDecimal;

    const actualPrice = Number(rest.discount) > 0 ? Number(rest.actual_price) - discountAmount : Number(rest.actual_price);


    let requestData;
    const requestConfig = {
      headers: { "Content-Type": id ? "application/json" : "multipart/form-data" },
    };

    if (id) {
      const medImageFiles = med_images.filter(item => item instanceof File);
      const adVBannerFiles = adV_banner.filter(item => item instanceof File);

      requestData = {
        ...initialState,
        category: categoryId,
        brand: brandId,
        available_size,
        related_products,
        actual_price: Number(rest.actual_price),
        discount: Number(rest.discount),
        total_price: actualPrice,
        faq,
      };

      const updatePromises = [];

      if (medImageFiles.length > 0) {
        const medImagesData = new FormData();
        medImageFiles.forEach(image => {
          medImagesData.append("med_images", image);
        });
        updatePromises.push(
          axiosAuthorized.put(`/update_med_images/${id}`, medImagesData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
        );
      }

      if (adVBannerFiles.length > 0) {
        const advBannerData = new FormData();
        adVBannerFiles.forEach(image => {
          advBannerData.append("adV_banner", image);
        });
        updatePromises.push(
          axiosAuthorized.put(`/update_advbanner_images/${id}`, advBannerData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
        );
      }
      updatePromises.push(
        axiosAuthorized.put(`/medicine/update_medicine/${id}`, requestData, requestConfig)
      );
      const responses = await Promise.all(updatePromises);
      return responses;
    } else {
      requestData = new FormData();
      med_images.forEach(item => {
        if (item instanceof File) {
          requestData.append("med_images", item);
        } else {
          requestData.append("med_images", item); 
        }
      });

      adV_banner.forEach(item => {
        if (item instanceof File) {
          requestData.append("adV_banner", item);
        } else {
          requestData.append("adV_banner", item); 
        }
      });

      related_products.forEach(id => {
        requestData.append("related_products", id);
      });

      available_size.forEach(size => {
        requestData.append("available_size", size);
      });

      requestData.append("category", categoryId);
      requestData.append("brand", brandId);

      faq.forEach((item, index) => {
        Object.entries(item).forEach(([item_key, item_value]) => {
          requestData.append(`faq[${index}][${item_key}]`, item_value);
        });
      });

      Object.entries(rest).forEach(([key, value]) => {
        requestData.append(key, value);
      });

      const response = await axiosAuthorized.post(`/medPhram/add_medPhram`, requestData, requestConfig);
      return response;
    }
  } catch (error) {
    throw error;
  }
};


export const deleteMedicine = async (id) => {
  try {
    const response = await axiosAuthorized.delete(`/medPhram/delete_medPhram/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}