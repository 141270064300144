import React, { useCallback, useEffect, useState } from 'react'
import { NoDataFound, SearchInput } from '../Common';
import { RiCoupon2Fill } from 'react-icons/ri';
import CouponsTable from './CouponsTable';
import { getCoupons, deleteCoupon } from '../../utils/couponsUtils/couponsUtils';
import { useAuth } from '../../context/useAuth';
import CouponModal from './CouponModal';

const CouponsMainSection = () => {
  const [datas, setDatas] = useState([ ]);
    const setDatasFn = useCallback(() => {
      getCoupons().then(res => setDatas(res.data.data)).catch(error => console.error(error));
  }, []);
  useEffect(() => {
    setDatasFn();
  }, [setDatasFn]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterCoupons, setFilteredData] = useState([]);
    const [editData,setEditData] = useState(null);
  const [open, setOpen] = useState(false);
  const user = useAuth().authState.user;
  useEffect(() => {
    const filteredData = datas?.filter((coupon) => {
      const codeMatch = coupon?.coupon_code?.toLowerCase()
        ?.includes(searchQuery.toLowerCase());
      const perMatch = coupon?.coupon_percentage?.toLowerCase()
        ?.includes(searchQuery.toLowerCase());
      return codeMatch || perMatch;
    });
    setFilteredData(filteredData);
  }, [datas, searchQuery]);
  return (
    <>
      {open && (
        <CouponModal
          setOpen={setOpen}
          setEditData={setEditData}
          setDatasFn={setDatasFn}
          editData={editData}
        />
      )}
      <div className=" flex w-full items-center gap-5 flex-col">
        <div className="flex w-fit h-fit text-xl font-semibold">Coupons</div>
        <div className="flex w-full h-full justify-center items-center">
          <div className="flex w-full md:w-1/2">
            <SearchInput
              placeholder={"Search by Code, Percentage"}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
          </div>
        </div>
      </div>
      <div className="flex w-full p-3 flex-col">
        <div className="flex w-full justify-between items-center h-fit">
          <span className="font-semibold text-base">Informations</span>
          <button
            style={{
              background: "#36306E",
            }}
            className={`text-white flex text-sm rounded-md gap-2 p-2 items-center`}
            onClick={() => setOpen(true)}
          >
            <span>
              <RiCoupon2Fill />
            </span>
            <span>Coupon</span>
          </button>
        </div>
        {filterCoupons?.length > 0 ? (
          <CouponsTable
            datas={filterCoupons}
            setEditData={setEditData}
            setDatasFn={setDatasFn}
            setOpen={setOpen}
            user={user}
          />
        ) : (
          <div className="flex mt-10">
            <NoDataFound data={"coupons"} />
          </div>
        )}
      </div>
    </>
  );
}

export default CouponsMainSection