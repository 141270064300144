import React, { useState } from "react";
import { GrFormClose } from "react-icons/gr";
import { ImageInput, ShowImage, TextInput } from "../Common/FormComponents";
import { toast } from "react-toastify";
import { useForm } from "../../hooks";
import { addBrand } from "../../utils/homeUtils/brandsUtils";

const BrandsModal = ({ setOpen, setDatasFn, editData = null, setEditData }) => {
    const [err, setErr] = useState({ global: "" });
    const initialState = {
        brand_name: editData?.brand_name || "",
    }
    const [formData, handleFormData, clearForm] = useForm(initialState);
    const [loading, setLoading] = useState(false);
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!formData.brand_name.length) return setErr({ global: "Invalid Name" });
        setErr({ global: "" });
        setLoading(true);
        addBrand(formData, editData?._id)
            .then((res) => {
                clearForm();
                setDatasFn();
                setOpen(false);
                setEditData(null);
                toast(`Brand ${editData ? 'Edited' : 'Added'}`, {
                    type: "success",
                    autoClose: 3000,
                });
            })
            .catch((err) => {
                console.error(err);
                toast(`Brand ${editData ? "Edit Failed" : "Add Failed"}`, {
                    type: "error",
                    autoClose: 3000,
                });
            })
            .finally(() => setLoading(false));
    };
    return (
        <div className="flex z-10 absolute w-full h-fit  rounded-md justify-start items-start bg-white pb-10">
            <div
                className=" flex  w-full max-w-screen-sm overflow-y-auto max-h-[400px] border shadow-lg bg-white rounded-md gap-4 px-4 pb-4 pt-2 flex-col"
            >
                <div className="flex w-full h-fit items-center justify-between font-semibold ">
                    <span>{"Brands"}</span>
                    <span
                        className="cursor-pointer"
                        onClick={() => {
                            setOpen(false);
                            setEditData(null);
                        }}
                    >
                        <GrFormClose fontSize={16} />
                    </span>
                </div>
                <div className="flex w-full h-fit justify-between gap-5 ">
                    <TextInput
                        data={formData}
                        formName={"brand_name"}
                        name="Name"
                        handleInput={handleFormData}
                        setError={setErr}
                    />
                </div>
                {err.global && (
                    <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
                        {err.global}
                    </div>
                )}
                <div className="flex w-full h-fit justify-between gap-5">
                    <div className=" flex flex-col w-full gap-0.5 ">
                        <button
                            className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
                            onClick={() => {
                                setEditData(null);
                                setOpen(false);
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                    <div className="flex flex-col w-full gap-0.5 ">
                        <button
                            className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
                            disabled={loading}
                            onClick={(e) => {
                                handleSubmit(e);
                            }}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BrandsModal;
