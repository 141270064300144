import React, { useEffect, useMemo, useState } from "react";
import { getMedicinesByCategory } from "../../utils/medicinesUtils/medicinesUtils";
import Table from "../Common/Table/Table";
import { NoDataFound } from "../Common";

const MedicinesList = ({ selectedCategory }) => {
  const [datas, setDatas] = useState([]);
  useEffect(() => {
      getMedicinesByCategory(selectedCategory?._id).then(res => {
        setDatas(res?.data?.data);
      }).catch(err => console.error(err));
  }, [selectedCategory?._id]);
  const headings = useMemo(
    () => ["Name", "ID", "Exp-Date", "Price", "Discount", "Total"],
    []
  );
  return datas?.length > 0 ? (
    <Table headings={headings}>
      {datas?.map((data, index) => (
        <tr key={index}>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.medicine_name}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.medicine_id}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.expiry_dates}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.actual_price}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.discount}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.total_price}
            </div>
          </td>
        </tr>
      ))}
    </Table>
  ) : (
    <div className="flex w-full justify-center items-center mt-10">
      <NoDataFound data={"medicines"} />
    </div>
  );
};

export default MedicinesList;
