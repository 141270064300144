import React, { useMemo } from "react";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { TbUserEdit } from "react-icons/tb";
import { deleteMedicine } from "../../utils/medicinesUtils/medicinesUtils";
import Table from "../Common/Table/Table";

const MedicinesTable = ({ datas, setOpen, setEditData, setMedicinesFn }) => {
  const headings = useMemo(
    () => [
      "Medicine",
      "Category",
      "Actual-Price",
      "Discount",
      "Total-Price",
      "Edit",
      "Delete",
    ],
    []
  );
  const delTest = (testId) => {
    deleteMedicine(testId)
      .then((res) => setMedicinesFn())
      .catch((err) => console.log(err));
  };
  return (
    <Table headings={headings}>
      {datas?.map((data, index) => (
        <tr key={index}>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.medicine_name}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.category?.category_name}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.actual_price}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.discount}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">{data?.total_price}</div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div
              className="text-sm text-gray-900 capitalize cursor-pointer"
              onClick={() => {
                setEditData(data);
                setOpen(true);
              }}
            >
              <TbUserEdit />
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div
              className="text-sm text-gray-900 capitalize cursor-pointer"
              onClick={() => {
                delTest(data?._id);
              }}
            >
              <MdOutlineDeleteOutline color="red" />
            </div>
          </td>
        </tr>
      ))}
    </Table>
  );
};

export default MedicinesTable;
