import React, { createContext, useState, useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import { axiosInstance } from '../api/config';

let globalAuthState = {
    token: null,
    user: null,
};
let globalLogOut = null;

export const getGlobalAuthState = () => globalAuthState;
export const setGlobalAuthState = (newState) => {
    globalAuthState = { ...globalAuthState, ...newState };
};

export const getGlobalLogOut = () => globalLogOut;

const AuthContext = createContext(undefined);

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

export const AuthProvider = ({ children }) => {
    const [authState, setAuthState] = useState(globalAuthState);
    const [isLoading, setIsLoading] = useState(true);

    const setAuthStateHandler = (newAuthState) => {
        setAuthState(newAuthState);
        setGlobalAuthState(newAuthState);
    };

    const logOut = async () => {
        try {
            setIsLoading(true);
            await axiosInstance.post('/memberpharmacy_logout', null, { withCredentials: true });
            setAuthStateHandler({ token: null, user: null });
            localStorage.clear();
            window.location.href = '/login';
        } catch (error) {
            toast.error('Something went wrong, please try again.');
        } finally {
            setIsLoading(false);
        }
    };
    globalLogOut = logOut;

    const checkPersistAndRefreshToken = async () => {
        try {
            setIsLoading(true);
            const response = await axiosInstance.post('/memberpharmacy_refreshtoken', null, { withCredentials: true });
            const { token, user } = response?.data?.data;
            return { token, user };
        } catch (error) {
            return { token: null, user: null };
        } finally {
            setIsLoading(false);
        }
    };

    const initAuth = async () => {
        const result = await checkPersistAndRefreshToken();
        setAuthStateHandler(result);
    };

    useEffect(() => {
        initAuth();
    }, []);

    if (isLoading) return <div className='min-h-screen min-w-full flex items-center justify-center'>
        <div className="flex items-center gap-4 text-xs text-gray-500">
            Loading...
        </div>
    </div>

    return (
        <AuthContext.Provider value={{ authState, setAuthState: setAuthStateHandler, logOut, isLoading }}>
            {children}
        </AuthContext.Provider>
    );
};
