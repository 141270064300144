import React from "react";
import CategoriesMainSection from "../../components/CategoriesComponents/CategoriesMainSection";
const Categories = () => {
  return (
    <div className=" w-full h-full px-2 py-10 sm:p-10 flex flex-col gap-4 relative">
      <CategoriesMainSection />
    </div>
  );
};

export default Categories;
