import React, { useMemo } from "react";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import Table from "../Common/Table/Table";
import { getCoupons, deleteCoupon } from '../../utils/couponsUtils/couponsUtils';

const CouponsTable = ({
  datas, setEditData, setOpen, setDatasFn, user
}) => {
  const tableHeadings = ["Coupon Code", "Percentage", "Edit", "Delete"];

  const delData = (id) => {
    const confirmed = window.confirm("Are you sure you want to delete ?");
    if (!confirmed) return;
    deleteCoupon(id).then(res => { setDatasFn() })
      .catch(error => console.error(error));
  }
  return (
    <Table headings={tableHeadings}>
      {datas?.map((data, index) => (
        <tr key={index}>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.coupon_code}
            </div>
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="text-sm text-gray-900 capitalize">
              {data?.coupon_percentage}
            </div>
          </td>
          <td
            className={`px-6 py-4 whitespace-nowrap `}
          >
            <div
              className="text-sm text-gray-900 capitalize cursor-pointer"
              onClick={() => {
                setEditData(data);
                setOpen(true);
              }}
            >
              <CiEdit color="green" />
            </div>
          </td>
          <td
            className={` px-6 py-4 whitespace-nowrap `}
          >
            <div
              className="text-sm text-gray-900 capitalize cursor-pointer"
              onClick={() => {
                delData(data?._id);
              }}
            >
              <MdOutlineDeleteOutline color="red" />
            </div>
          </td>
        </tr>
      ))}
    </Table>
  );
};

export default CouponsTable;
