import React from "react";
import HomeMainSection from "../../components/HomeComponents/HomeMainSection";
const Home = () => {
    return (
        <div className=" w-full h-full px-2 py-10 sm:p-10 flex flex-col gap-4 relative">
            <HomeMainSection />
        </div>
    );
};

export default Home;
